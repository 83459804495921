import {
  registerMicroApps, runAfterFirstMounted,
  setDefaultMountApp, start,
} from 'qiankun';
import { appParam } from '@/config';
import render from './render';
import '@/assets/css/reset.css';
import '@/assets/css/index.css';

const loader = loading => render({ loading });
registerMicroApps(
  [
    {
      name: appParam.bd.name,
      entry: process.env.NODE_ENV === 'production' ?
        appParam.bd.entry : '//localhost:8082',
      container: '#subapp-viewport',
      loader,
      activeRule: appParam.bd.path,
    },
    {
      name: appParam.kx.name,
      entry: '//localhost:8282',
      entry: process.env.NODE_ENV === 'production' ?
        appParam.kx.entry : '//localhost:8282',
      container: '#subapp-viewport',
      loader,
      activeRule: appParam.kx.path,
    },
    {
      name: appParam.bh.name,
      entry: '//localhost:8083',
      entry: process.env.NODE_ENV === 'production' ?
        appParam.bh.entry : '//localhost:8083',
      container: '#subapp-viewport',
      loader,
      activeRule: appParam.bh.path,
    },
    {
      name: appParam.mall.name,
      entry: '//localhost:8084',
      entry: process.env.NODE_ENV === 'production' ?
        appParam.mall.entry : '//localhost:8084',
      container: '#subapp-viewport',
      loader,
      activeRule: appParam.mall.path,
    },
    {
      name: appParam.datacenter.name,
      loader,
      activeRule: appParam.datacenter.path,
      entry: process.env.NODE_ENV === 'production' ?
        appParam.datacenter.entry : '//localhost:8088',
      container: '#subapp-viewport',
    },
    {
      name: appParam.sp.name,
      entry: '//localhost:8085',
      entry: process.env.NODE_ENV === 'production' ?
          appParam.sp.entry : '//localhost:8085',
      container: '#subapp-viewport',
      loader,
      activeRule: appParam.sp.path,
    },
  ],
  // {
  //   beforeLoad: [
  //     app => {
  //       console.log('[LifeCycle] before load %c%s', 'color: green;', app.name);
  //     },
  //   ],
  //   beforeMount: [
  //     app => {
  //       console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
  //     },
  //   ],
  //   afterUnmount: [
  //     app => {
  //       console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
  //     },
  //   ],
  // },
);

// const { onGlobalStateChange, setGlobalState } = initGlobalState({
//   user: 'qiankun',
// });

// onGlobalStateChange((value, prev) => console.log('[onGlobalStateChange - master]:', value, prev));

// setGlobalState({
//   ignore: 'master',
//   user: {
//     name: 'master',
//   },
// });
/**
 * Step3 设置默认进入的子应用
 */
let url = `/${appParam.kx.path}`;
try {
  switch (JSON.parse(localStorage.userInfo).team_type) {
    case 3:
    case 4:
    case 8:
      url = `/${appParam.bd.path}`;
      break;
    default:
      url = `/${appParam.kx.path}`;
  }
} catch (e) {

}

setDefaultMountApp(url);

/**
 * Step4 启动应用
 */
start({
  prefetch: false,
  excludeAssetFilter(url) {
    // print
    return ['http://localhost:18000/CLodopfuncs.js', 'http://localhost:8000/CLodopfuncs.js'].indexOf(url) > -1;
  }
});

runAfterFirstMounted(() => {
  console.log('[MainApp] first app mounted');
});
